import React from "react";
import './home.css'

function Home() {
  return (
    <div className="page-wrapper">
    <div className="nav">
      <div data-animation="default" data-collapse="medium" data-duration="400" data-easing="ease" data-easing2="ease" role="banner" className="navbar w-nav">
        <div className="wrapper navbar-wrapper">
          <div className="container navbar-container">
            <a href="https://watts.slotie.com" className="brand w-nav-brand"><img src="images/Slotie-Logo.svg" loading="eager" alt="Slotie Logo." className="logo nav-logo"/>
              <div className="text logo-text">Slotie</div>
            </a>
            <nav role="navigation" className="nav-menu w-nav-menu">
              <div className="grid nav-menu-grid">
                <div className="grid nav-links-grid">
                  <a href="#About" className="nav-link w-nav-link">What are Watts?</a>
                  <a href="#Gain" className="nav-link w-nav-link">Gain</a>
                  <a href="#Reward" className="nav-link w-nav-link"> Reward</a>
                  <a href="#Breeding" className="nav-link w-nav-link">Breeding</a>
                  <a href="#Voting" className="nav-link w-nav-link">Voting</a>
                  <a href="#Customize" className="nav-link w-nav-link">Customize</a>
                </div>
                <a href="/claim" className="button small nav-button w-button">CLAIM!</a>
                <div className="grid social-links-grid">
                  <a id="w-node-_8f78bbae-74c8-aa00-d66c-8c5459c9010e-6b8be006" href="https://twitter.com/SlotieNft" target="_blank" className="link-block social-link w-inline-block"><img src="images/Logo-white-2.svg" loading="eager" alt="Twitter Logo." className="icon social-icon"/></a>
                  <a href="https://discord.gg/slotie" target="_blank" className="link-block social-link w-inline-block"><img src="images/Discord-Logo-White-1.svg" loading="eager" alt="Discord Logo." className="icon social-icon"/></a>
                </div>
              </div>
              <div className="horizontal-line navbar-line mobile-navbar-line"></div>
            </nav>
            <div className="menu-button w-nav-button">
              <div className="w-icon-nav-menu"></div>
            </div>
          </div>
        </div>
      </div>
      <div className="horizontal-line navbar-line"></div>
    </div>
    <main id="Home" className="section hero-section">
      <div className="container">
        <div className="grid hero-grid">
          <div className="wrapper">
            <div className="wrapper">
              <div className="wrapper heading-block-wrapper">
                <div className="horizontal-line _2em-width"></div>
                <div className="spacer _0-5em"></div>
                <div className="text hero-subtitle-text">Watts tokens</div>
                <div className="spacer _0-5em"></div>
                <div className="horizontal-line grow"></div>
              </div>
              <div className="spacer _0-5em"></div>
              <h1 className="heading">Become a pioneer in an exciting new universe.</h1>
              <p className="text text-large">Start your journey today with Watts.</p>
              <div className="spacer _0-5em"></div>
            </div>
            <div className="wrapper button-row">
              <a href="/claim" className="button w-button">Claim now</a>
              <a href="/transfer" className="button w-button">Transfer now</a>
              <a href="/bridge" className="button w-button">Bridge now</a>
              <a href="#About" className="button secondary w-button">What are Watts?</a>
            </div>
          </div>
          <div data-w-id="8976eeec-801c-a0ff-1530-9df20364d267" data-animation-type="lottie" data-src="documents/Watts.json" data-loop="1" data-direction="1" data-autoplay="1" data-is-ix2-target="0" data-renderer="svg" data-default-duration="5.366666666666666" data-duration="0" className="lottie hero-watts-lottie"></div>
        </div>
        <div className="wrapper hero-scroll-lottie-wrapper">
          <a href="#About" className="link-block hero-scroll-lottie-link w-inline-block">
            <div data-w-id="017efade-af9e-6411-1bb8-95d86e52bd23" data-animation-type="lottie" data-src="documents/lottieflow-scroll-down-09-ffffff-easey.json" data-loop="1" data-direction="1" data-autoplay="1" data-is-ix2-target="0" data-renderer="svg" data-default-duration="2" data-duration="0" className="lottie hero-scroll-lottie"></div>
          </a>
        </div>
      </div>
    </main>
    <section id="About" className="section">
      <div className="container">
        <div className="wrapper heading-block-wrapper">
          <h2 className="heading heading-large no-margin">What are Watts?</h2>
          <div className="spacer _1em"></div>
          <div className="horizontal-line grow"></div>
        </div>
        <div className="spacer _1-25em"></div>
        <div className="grid about-section-grid">
          <div className="wrapper">
            <div className="grid about-section-content-grid">
              <p className="text">Watt tokens are the core of Slotie Gaming Universe.<br/><br/>The larger our universe becomes, the more utility our token will develop. Holding a Slotie means being a pioneer in the largest and fastest growing online Casino network transitioning from Web2 to Web3.<br/><br/>A fantastic community and amazing utility make Watts one of the most important token to hold. Discover the real universe on the blockchain with Watts.</p>
              <div id="Gain" className="grid about-section-content-row"><img src="images/icons/Gain.png" loading="lazy" id="w-node-_8ce4a08c-d1fc-f505-786e-cc4351d212c8-6b8be006" alt="" className="image row-image"/>
                <div className="wrapper">
                  <h3>Gain</h3>
                  <p className="text" style={{marginBottom: 0}}>Every Slotie gains <strong>10 Watts per day as a deserved gift.</strong></p>
                </div>
              </div>
              <div id="Reward" className="grid about-section-content-row">
                <div className="wrapper">
                  <h3>Holder&#x27;s Reward</h3>
                  <p className="text">Based on the rarity power of your Slotie, on specific days throughout the Slotie universe, you will be rewarded with powerful Watts tokens.</p>
                </div><img src="images/icons/Holder.png" loading="lazy" alt="" className="image row-image"/>
              </div>
              <div id="Breeding" className="grid about-section-content-row"><img src="images/icons/Breeding.png" loading="lazy" alt="" className="image row-image"/>
                <div className="wrapper">
                  <h3>Breeding</h3>
                  <p className="text">Hold your two awesome Sloties in your wallet and burn 1800 Watts to receive an incubator aboard the Mars spaceship.</p>
                </div>
              </div>
              <div id="Voting" className="grid about-section-content-row">
                <div className="wrapper">
                  <h3>Voting Rights</h3>
                  <p className="text">Watts tokens are not just infused with utility. They also form the basis for influence and voting on the Decentralized Autonomous Organization (DAO).</p>
                </div><img src="images/icons/Voting.png" loading="lazy" alt="" className="image row-image"/>
              </div>
              <div id="Customize" className="grid about-section-content-row"><img src="images/icons/Customisation.png" loading="lazy" alt="" className="image row-image"/>
                <div className="wrapper">
                  <h3>Customize Your Sloties</h3>
                  <p style={{ marginBottom: 0 }} className="text">Love your Slotie and wish you could name it? Well now you can! For 100 Watts, you can name your Slotie whatever you can dream of.<br/><br/>Is a name not enough? Write a description for your Slotie for 50 Watts. How awesome is that?!</p>
                </div>
              </div>
            </div>
          </div>
          <div className="grid about-section-sticky-grid">
            <div className="wrapper card">
              <div className="heading heading-small">Watts Smart Contact:</div>
              <a href="https://bscscan.com/address/0x5fdB2B0C56Afa73B8ca2228e6aB92Be90325961d" target="_blank" className="link smart-contract-link">0x5fdB2B0C56Afa73B8ca2228e6aB92Be90325961d</a>
            </div>
            <div className="wrapper card">
              <h4>Welcome to the future.</h4>
              <p className="text text-small">
                  Watts are utility tokens that fuel the Slotie machines. They are NOT an investment and have NO economic value.<br/><br/>Each Slotie is able to claim <strong>10 Watts  tokens per day</strong> and token supply is unlimited!
                  <br/>
                  <br/>
                  WATTs is an internal currency & 1 WATTs = 1 WATTs
                  </p>
              <div className="spacer _0-5em"></div>
              <div className="wrapper social-row">
                <a href="https://twitter.com/SlotieNft" target="_blank" className="link-block social-link w-inline-block"><img src="images/Logo-white-2.svg" loading="lazy" alt="Twitter Logo." className="icon social-icon"/></a>
                <div className="spacer _1em"></div>
                <a href="https://discord.gg/slotie" target="_blank" className="link-block social-link w-inline-block"><img src="images/Discord-Logo-White-1.svg" loading="lazy" alt="Discord Logo." className="icon social-icon"/></a>
                <div className="spacer _1em"></div>
              </div>
            </div>
            <div className="wrapper card">
              <h4>Start your journey today.</h4>
              <p className="text text-small">A whole new world awaits.</p>
              <div className="wrapper button-row">
                <a href="/claim" className="button small w-button">Claim now</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <footer className="section footer-section">
      <div className="container footer-container">
        <div className="grid footer-grid">
          <a href="https://www.slotie.com" id="w-node-fa8bc22b-940a-a591-4ef0-fb26e11756cb-6b8be006" className="brand w-nav-brand"><img src="images/Slotie-Logo.svg" loading="eager" alt="Slotie Logo." className="logo footer-logo"/>
            <div className="text logo-text footer-logo-text">Slotie</div>
          </a>
          <div className="grid footer-subgrid">
            <div className="text">Become a member of the strongest and fast growing DeFi gambling community.<br/></div>
            <div className="grid social-links-grid">
              <a id="w-node-_85111364-03ec-d5ca-8481-6c611792f731-6b8be006" href="https://twitter.com/SlotieNft" target="_blank" className="link-block social-link w-inline-block"><img src="images/Logo-white-2.svg" loading="eager" alt="Twitter Logo." className="icon social-icon"/></a>
              <a href="https://discord.gg/slotie" target="_blank" className="link-block social-link w-inline-block"><img src="images/Discord-Logo-White-1.svg" loading="eager" alt="Discord Logo." className="icon social-icon"/></a>
              <a href="/claim" id="w-node-f8ddfbed-2104-de74-2399-5a6b1e817fe9-6b8be006" className="button small nav-button w-button">CLAIM!</a>
            </div>
          </div>
          <div className="horizontal-line grow"></div>
          <div className="grid footer-subgrid">
            <div className="text text-small">© 2022 Slotie NFT. All rights reserved.<br/></div>
            <a href="https://www.slotie.com/terms" target="_blank" className="link terms-link">Terms &amp; Conditions</a>
          </div>
        </div>
      </div>
    </footer>
  </div>
  );
}

export default Home;
