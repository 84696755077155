import React, { useState, useEffect, useCallback } from "react";
import { BigNumber, ethers } from "ethers";
import "./transfer.css";

import Config from "../config";

import useProofs from "../hooks/useProofs";
import useWeb3 from "../hooks/useWeb3";

export default function Claim() {
  useEffect(() => {
    window.Webflow && window.Webflow.destroy();
    window.Webflow && window.Webflow.ready();
    window.Webflow && window.Webflow.require("ix2");
    //document.dispatchEvent(new Event('readystatechange'))
  });

  const [
    provider,
    signer,
    address,
    contractWatts,
    contractClaim,
    contractTransfer,
    contractSlotie,
    contractBridge,
    isConnected,
    chainId,
    tryAutoConnect,
    triedAutoConnecting,
    setTriedAutoConnecting,
    connect,
    isConnecting,
    isCorrectChain
  ] = useWeb3();

  const now = () => Math.floor(Date.now() / 1000);
  const zeroBalance = ethers.BigNumber.from(10).pow(16);

  const [wattsClaimableBalance, setWattsClaimableBalance] = useState();
  const [wattsBalance, setWattsBalance] = useState();
  const [approvedWatts, setApprovedWatts] = useState();
  const [correctAmount, setCorrectAmount] = useState();
  const [correctRecipient, setCorrectRecipient] = useState();
  const [transferAmount, setTransferAmount] = useState(0);
  const [recipient, setRecipient] = useState();

  const isDataLoaded = () =>
    wattsBalance !== undefined && wattsClaimableBalance !== undefined && approvedWatts !== undefined;
  const isWattsBalanceZero = () => isDataLoaded() && wattsBalance.eq(0) && wattsClaimableBalance.eq(0);

  const twoDecimals = str => {
    //console.log(str)
    const split = str.split(".");
    //console.log(split)
    if (split.length > 1) {
      return split[0] + "." + split[1].substring(0, 2);
    } else {
      return str;
    }
  };

  const getBalances = async () => {
    if (address && contractWatts && contractTransfer && chainId) {
      try {
        const totalWattsBalance = await contractWatts.balanceOf(address);
        const approvedWatts = await contractWatts.allowance(address, contractTransfer.address);
        if (isEth(chainId)) {
          const claimableWattsBalance = await contractTransfer.WATTSOWNER_seeClaimableBalanceOfUser(address);
          setWattsClaimableBalance(claimableWattsBalance);
          setWattsBalance(totalWattsBalance.sub(claimableWattsBalance));
        } else {
          setWattsClaimableBalance(0);
          setWattsBalance(totalWattsBalance);
        }
        setApprovedWatts(approvedWatts);

      } catch (e) {
        console.log(e);
      }
    }
  };

  const isEth = (chainId) => {
    console.log(chainId)
    if (!chainId) return true;
    return chainId === "1" || chainId === "5";
  }

  useEffect(() => {
    getBalances();
  }, [address, contractWatts, contractTransfer, chainId]);

  useEffect(() => {
    if (provider && contractWatts && contractTransfer) {
      provider.on("block", _ => {
        console.log("block update");
        getBalances();
      });
      return () => provider.off("block");
    }
  }, [provider, contractWatts, contractTransfer]);
  //useOnBlock(injectedProvider, () => console.log("avaa"));

  const [isTransfering, setIsTransfering] = useState(false);
  const [isError, setIsError] = useState(false);
  const [inputError, setInputError] = useState();
  const [isTransfered, setIsTransfered] = useState(false);

  const [status, setStatus] = useState(0);
  const [txId, setTxId] = useState("");

  useEffect(() => {
    checkTx();
  }, [status]);

  const checkTx = () => {
    if (provider) {
      const txId = localStorage.getItem("PENDING_TX");
      console.log("txId", txId);
      if (txId && txId !== "") {
        provider.getTransactionReceipt(txId).then(receipt => {
          console.log("getTransactionReceipt", receipt);
          if (receipt) {
            localStorage.setItem("PENDING_TX", "");
            setIsTransfering(false);
            setStatus(0);
            setTxId(receipt.transactionHash);
            if (receipt.status == true) {
              setIsTransfered(true);
            } else {
              setIsError(true);
            }
            getBalances();
          } else {
            setTimeout(checkTx, 1000);
          }
        });
      }
    }
  };

  const areInputsCorrect = () => inputError === undefined && correctAmount && correctRecipient;

  const shouldApprove = () => {
    if (areInputsCorrect() && isDataLoaded()) {
      let amount = transferAmount;

      let toApprove = amount.sub(wattsClaimableBalance);
      if (toApprove.lte(0)) return false;
      else {
        return approvedWatts.lt(toApprove);
      }
    } else {
      return false;
    }
  };

  const setWalletFromInput = value => {
    let addr = value.replaceAll(`"`, "").replaceAll(`'`, "").replaceAll("`", "").trim();

    if (!ethers.utils.isAddress(addr)) {
      setInputError("Please enter a valid address");
      setCorrectRecipient(false);
      return;
    }

    setRecipient(addr);
    setInputError(undefined);
    setCorrectRecipient(true);
  };

  const setAmountFromInput = value => {
    let amount = parseFloat(value);

    if (isNaN(amount)) {
      setInputError("Please enter a valid number for the amount");
      setCorrectAmount(false);
      return;
    }

    if (amount <= 0) {
      setInputError("Please enter a number above 0");
      setCorrectAmount(false);
      return;
    }

    amount = Math.floor(amount * 10 ** 5);

    amount = ethers.BigNumber.from(10)
      .pow(18 - 5)
      .mul(amount);

    if (wattsBalance.add(wattsClaimableBalance).lt(amount)) {
      setInputError(
        `Amount too high. Maximum you can transfer is ${twoDecimals(
          ethers.utils.formatEther(wattsBalance.add(wattsClaimableBalance).toString()),
        )} watts`,
      );
      setCorrectAmount(false);
      return;
    }

    setTransferAmount(amount);
    setInputError(undefined);
    setCorrectAmount(true);
  };

  const approve = async () => {
    setIsTransfering(true);

    if (inputError !== undefined) {
      alert("There are still some input errors.");
      return;
    }

    if (transferAmount == undefined) {
      alert("Please enter a valid amount to send");
      return;
    }
    const toApprove = transferAmount.sub(wattsClaimableBalance);
    if (toApprove.lte(0)) {
      alert("No approval needed");
      return;
    }

    contractWatts
      .connect(signer)
      .approve(contractTransfer.address, ethers.constants.MaxUint256)
      .then(response => {
        console.log("response", response);
        localStorage.setItem("PENDING_TX", response.hash);
        setStatus(1);
        return response.hash;
      })
      .catch(error => {
        console.log(error);
        setStatus(0);
        setIsTransfering(false);
        setIsError(true);
      });
  };

  const transfer = async () => {
    setIsTransfering(true);

    if (inputError !== undefined) {
      alert("There are still some input errors.");
      return;
    }

    if (transferAmount == undefined) {
      alert("Please enter a valid amount to send");
      return;
    }

    if (recipient == undefined || recipient == "") {
      alert("Please enter a valid recipient");
      return;
    }

    contractTransfer
      .connect(signer)
      .transfer(transferAmount, recipient)
      .then(response => {
        console.log("response", response);
        localStorage.setItem("PENDING_TX", response.hash);
        setStatus(1);
        return response.hash;
      })
      .catch(error => {
        console.log(error);
        setStatus(0);
        setIsTransfering(false);
        setIsError(true);
      });
  };

  return (
    <div className="page-wrapper">
      <div className="nav">
        <div
          data-animation="default"
          data-collapse="medium"
          data-duration="400"
          data-easing="ease"
          data-easing2="ease"
          role="banner"
          className="navbar w-nav"
        >
          <div className="wrapper navbar-wrapper">
            <div className="container navbar-container">
              <a href="https://watts.slotie.com" className="brand w-nav-brand">
                <img src="images/Slotie-Logo.svg" loading="eager" alt="Slotie Logo." className="logo nav-logo" />
                <div className="text logo-text">Slotie</div>
              </a>
              <nav role="navigation" className="nav-menu w-nav-menu">
                <div className="grid nav-menu-grid">
                  <div className="grid nav-links-grid">
                    <a href="index.html" className="nav-link w-nav-link">
                      Home
                    </a>
                  </div>
                  <div className="grid social-links-grid">
                    <a
                      id="w-node-_8f78bbae-74c8-aa00-d66c-8c5459c9010e-9ab278cb"
                      href="https://twitter.com/SlotieNft"
                      target="_blank"
                      className="link-block social-link w-inline-block"
                      rel="noreferrer"
                    >
                      <img
                        src="images/Logo-white-2.svg"
                        loading="eager"
                        alt="Twitter Logo."
                        className="icon social-icon"
                      />
                    </a>
                    <a
                      href="https://discord.gg/slotie"
                      target="_blank"
                      className="link-block social-link w-inline-block"
                      rel="noreferrer"
                    >
                      <img
                        src="images/Discord-Logo-White-1.svg"
                        loading="eager"
                        alt="Discord Logo."
                        className="icon social-icon"
                      />
                    </a>
                  </div>
                </div>
                <div className="horizontal-line navbar-line mobile-navbar-line"></div>
              </nav>
              <div className="menu-button w-nav-button">
                <div className="w-icon-nav-menu"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="horizontal-line navbar-line"></div>
      </div>

      {
        isCorrectChain === false ? (
          <section id="Claim" className="section wallet-section">
            <div className="container"> 
            <div className="wrapper card">
              <h3 className="heading heading-xl no-margin" style={{ textAlign: "center" }}>
                Your wallet is connected to an unsupported network. Please switch your network to Ethereum or Binance Smart Chain.
              </h3>
            </div>
            </div>
          </section>
        ) : (
          <section id="Claim" className="section wallet-section">
            <div className="container">
              <div className="wrapper heading-block-wrapper">
                <div className="horizontal-line grow"></div>
                <div className="spacer _1em"></div>
                <h1 className="heading heading-xl no-margin">Transfer Watts</h1>
                <div className="spacer _1em"></div>
                <div className="horizontal-line grow"></div>
              </div>
              <div className="spacer _2em"></div>
              <div className="wrapper card">
                <div className="grid wallet-grid">
                  <div className="wrapper">
                    <h2>You can transfer watts here.</h2>
                    <p className="text">
                      Enter the amount of watts you want to transfer and the recipient's Ethereum address and press
                      transfer. The transfer should take a few minutes to complete, after which your balances will be
                      updated.
                    </p>

                    {!isConnected && !isConnecting && (
                      <a
                        onClick={e => {
                          e.preventDefault();
                          connect();
                        }}
                        href="#"
                        className="button w-button"
                      >
                        Connect wallet
                      </a>
                    )}

                    {isConnecting && !isConnected && (
                      <div
                        data-w-id="4a714206-6628-824b-d7eb-e3532f4fa8a8"
                        data-animation-type="lottie"
                        data-src="https://uploads-ssl.webflow.com/61a2301c1aba757f9b2521aa/61a2301c1aba75799b2521d9_lottieflow-loading-04-1-ffffff-easey.json"
                        data-loop="1"
                        data-direction="1"
                        data-autoplay="1"
                        data-is-ix2-target="0"
                        data-renderer="svg"
                        data-default-duration="3.1458333333333335"
                        data-duration="0"
                        className="lottie loading-lottie"
                      ></div>
                    )}

                    {isConnected && (
                      <div className="tag connected-tag">
                        Wallet Connected<span className="span"></span>
                      </div>
                    )}
                  </div>

                  <div className="wallet-line"></div>

                  <div id="w-node-_6cbb3016-9612-9d1b-574c-2c7813d436db-9ab278cb" className="wrapper wallet-column right">
                    {isConnected && isDataLoaded() && wattsBalance.add(wattsClaimableBalance).gte(zeroBalance) && (
                      <>
                        <div className="wrapper wallet-block wallet-phase-03">
                          <div className="wrapper wallet-row">
                            <div className="heading wallet-heading">Total Balance:</div>
                            <div className="tag wallet-tag">
                              {twoDecimals(ethers.utils.formatEther(wattsBalance.add(wattsClaimableBalance).toString()))}
                              <span className="span"></span>
                            </div>
                          </div>
                          <div className="wrapper input-item">
                            <div className="wallet-heading">Watts amount to transfer:</div>
                            <input type="number" onChange={value => setAmountFromInput(value.target.value)} />
                          </div>
                          <div className="wrapper input-item">
                            <div className="wallet-heading">Address:</div>
                            <input type="text" onChange={value => setWalletFromInput(value.target.value)} />
                          </div>
                        </div>

                        {areInputsCorrect() && (
                          <div className="wrapper claim-watts-wrapper">
                            {!isTransfering && !isError && !shouldApprove() && (
                              <a
                                onClick={e => {
                                  e.preventDefault();
                                  transfer();
                                }}
                                href="#"
                                className="button w-button"
                              >
                                Transfer
                              </a>
                            )}

                            {!isTransfering && !isError && shouldApprove() && (
                              <a
                                onClick={e => {
                                  e.preventDefault();
                                  approve();
                                }}
                                href="#"
                                className="button w-button"
                              >
                                Approve
                              </a>
                            )}

                            {isTransfering && (
                              <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                <div
                                  data-w-id="7c63db2b-ec3c-cf00-594d-cf6c121b75f3"
                                  data-animation-type="lottie"
                                  data-src="https://uploads-ssl.webflow.com/61a2301c1aba757f9b2521aa/61a2301c1aba75799b2521d9_lottieflow-loading-04-1-ffffff-easey.json"
                                  data-loop="1"
                                  data-direction="1"
                                  data-autoplay="1"
                                  data-is-ix2-target="0"
                                  data-renderer="svg"
                                  data-default-duration="3.1458333333333335"
                                  data-duration="0"
                                  className="lottie loading-lottie"
                                ></div>

                                {status === 0 && (
                                  <div className="text text-small" style={{ marginLeft: 10 }}>
                                    Please confirm the transaction.
                                  </div>
                                )}

                                {status === 1 && (
                                  <div className="text text-small" style={{ marginLeft: 10 }}>
                                    Transaction is loading.
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        )}
                        {!isTransfering && isTransfered && (
                          <>
                            <div className="watts-claimed">
                              <div className="tag connected-tag">
                                <span className="span">Transaction successfull</span>
                              </div>
                            </div>
                          </>
                        )}
                        {!isTransfering && isError && <div className="tag error-tag">Transfer failed</div>}
                        {!isTransfering && inputError && <div className="tag error-tag">{inputError}</div>}
                      </>
                    )}
                    {isConnected && isDataLoaded() && wattsBalance.add(wattsClaimableBalance).lt(zeroBalance) && (
                      <div className="wrapper wallet-block wallet-phase-03">
                        <div className="wrapper wallet-row">
                            <div className="heading wallet-heading">You don't have any Watts to transfer</div>
                        </div>
                    </div>
                    )}

                    {!isConnected && (
                      <>
                        <div className="wrapper wallet-block wallet-phase-01">
                          <div className="wrapper wallet-row">
                            <div className="heading wallet-heading">Please connect your wallet to transfer your watts.</div>
                          </div>
                        </div>
                      </>
                    )}

                    {isConnected && !isDataLoaded() && (
                      <div className="wrapper wallet-block wallet-phase-01">
                        <div
                          data-w-id="4a714206-6628-824b-d7eb-e3532f4fa8a8"
                          data-animation-type="lottie"
                          data-src="https://uploads-ssl.webflow.com/61a2301c1aba757f9b2521aa/61a2301c1aba75799b2521d9_lottieflow-loading-04-1-ffffff-easey.json"
                          data-loop="1"
                          data-direction="1"
                          data-autoplay="1"
                          data-is-ix2-target="0"
                          data-renderer="svg"
                          data-default-duration="3.1458333333333335"
                          data-duration="0"
                          className="lottie loading-lottie"
                        ></div>
                        <div className="text text-small">Loading your data.</div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
        )
      }
      
      <footer className="section footer-section">
        <div className="container footer-container">
          <div className="grid footer-grid">
            <a
              href="https://www.slotie.com"
              id="w-node-fa8bc22b-940a-a591-4ef0-fb26e11756cb-9ab278cb"
              className="brand w-nav-brand"
            >
              <img src="images/Slotie-Logo.svg" loading="eager" alt="Slotie Logo." className="logo footer-logo" />
              <div className="text logo-text footer-logo-text">Slotie</div>
            </a>
            <div className="grid footer-subgrid">
              <div className="text">
                Become a member of the strongest and fast growing DeFi gambling community.
                <br />
              </div>
              <div className="grid social-links-grid">
                <a
                  id="w-node-_85111364-03ec-d5ca-8481-6c611792f731-9ab278cb"
                  href="https://twitter.com/SlotieNft"
                  target="_blank"
                  className="link-block social-link w-inline-block"
                  rel="noreferrer"
                >
                  <img src="images/Logo-white-2.svg" loading="eager" alt="Twitter Logo." className="icon social-icon" />
                </a>
                <a
                  href="https://discord.gg/slotie"
                  target="_blank"
                  className="link-block social-link w-inline-block"
                  rel="noreferrer"
                >
                  <img
                    src="images/Discord-Logo-White-1.svg"
                    loading="eager"
                    alt="Discord Logo."
                    className="icon social-icon"
                  />
                </a>
              </div>
            </div>
            <div className="horizontal-line grow"></div>
            <div className="grid footer-subgrid">
              <div className="text text-small">
                © 2022 Slotie NFT. All rights reserved.
                <br />
              </div>
              <a href="https://www.slotie.com/terms" target="_blank" className="link terms-link" rel="noreferrer">
                Terms &amp; Conditions
              </a>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}
