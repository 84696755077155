import { useState } from "react";
import Web3Modal from "web3modal";
// @ts-ignore
import WalletConnectProvider from "@walletconnect/web3-provider";
import WalletLink from "walletlink"
import Portis from "@portis/web3";
import Authereum from "authereum";
import Fortmatic from "fortmatic";
import { ethers } from "ethers";
import Contracts from "../contracts/hardhat_contracts.json";

import Config from "../config";

const walletLink = new WalletLink({
    appName: "coinbase",
});

const walletLinkProvider = walletLink.makeWeb3Provider(`https://mainnet.infura.io/v3/e26409fe0a7343458a12e059c7ada225`, 1);

const providerOptions = {
  walletconnect: {
    package: WalletConnectProvider,
    options: {
      infuraId: "e26409fe0a7343458a12e059c7ada225",
    },
  },
  portis: {
    display: {
      logo: "https://user-images.githubusercontent.com/9419140/128913641-d025bc0c-e059-42de-a57b-422f196867ce.png",
      name: "Portis",
      description: "Connect to Portis App",
    },
    package: Portis,
    options: {
      id: "6255fb2b-58c8-433b-a2c9-62098c05ddc9",
    },
  },
  fortmatic: {
    package: Fortmatic, // required
    options: {
      key: "pk_live_C3889285E72240F8", // required
    },
  },
  // torus: {
  //   package: Torus,
  //   options: {
  //     networkParams: {
  //       host: "https://localhost:8545", // optional
  //       chainId: 1337, // optional
  //       networkId: 1337 // optional
  //     },
  //     config: {
  //       buildEnv: "development" // optional
  //     },
  //   },
  // },
  authereum: {
    package: Authereum, // required
  },
  "custom-walletlink": {
    display: {
      logo: "https://play-lh.googleusercontent.com/PjoJoG27miSglVBXoXrxBSLveV6e3EeBPpNY55aiUUBM9Q1RCETKCOqdOkX2ZydqVf0",
      name: "Coinbase",
      description: "Connect to Coinbase Wallet (not Coinbase App)",
    },
    package: walletLinkProvider,
    connector: async (provider, _options) => {
      await provider.enable();
      return provider;
    },
  },
};

const web3Modal = new Web3Modal({
  cacheProvider: true,
  providerOptions,
  disableInjectedProvider: false, // optional. For MetaMask / Brave / Opera.
});

export default function useWeb3() {
  const [provider, setProvider] = useState(null);
  const [signer, setSigner] = useState(null);
  const [address, setAddress] = useState(null);
  const [contractWatts, setContractWatts] = useState(null);
  const [contractClaim, setContractClaim] = useState(null);
  const [contractTransfer, setContractTransfer] = useState();
  const [contractSlotie, setContractSlotie] = useState(null);
  const [contractBridge, setContractBridge] = useState(null);
  const [isConnected, setIsConnected] = useState(false);
  const [isConnecting, setIsConnecting] = useState(false);
  const [chainId, setChainId] = useState(null);
  const [isCorrectChain, setIsCorrectChain] = useState();
  const [triedAutoConnecting, setTriedAutoConnecting] = useState(null);

  async function initiateWeb3(proxy) {
    const _provider = new ethers.providers.Web3Provider(proxy);
    const _ethProvider = new ethers.providers.JsonRpcProvider(Config.NETWORK.ETH_RPC_URL);

    _provider.on("disconnect", (code, reason) => {
      console.log(code, reason);
      setIsConnected(false);
    });

    const _signer = await _provider.getSigner();
    const _address = await _signer.getAddress();
    let { chainId } = await _provider.getNetwork();
    const ethChainID = Config.NETWORK.TARGET_CHAIN_IDs[0];
    const _isCorrectChain = Config.NETWORK.TARGET_CHAIN_IDs.includes(chainId);
    chainId = chainId.toString();
    const isEth = chainId === "1" || chainId === "5";

    let _contractWatts;
    let _contractClaim;
    let _contractTransfer;
    let _contractSlotie;
    let _contractBridge;

    if (_isCorrectChain) {
      if (isEth) {
        let Watts = Contracts[chainId][Config.NETWORK.CHAIN_ID_TO_NAME[chainId]]["contracts"][Config.CONTRACTS.WATTS]
        _contractWatts = new ethers.Contract(Watts.address, Watts.abi, _provider);

        let Claim =
          Contracts[chainId][Config.NETWORK.CHAIN_ID_TO_NAME[chainId]]["contracts"][Config.CONTRACTS.WATTS_CLAIM];
        _contractClaim = new ethers.Contract(Claim.address, Claim.abi, _provider);

        let Transfer =
          Contracts[chainId][Config.NETWORK.CHAIN_ID_TO_NAME[chainId]]["contracts"][Config.CONTRACTS.WATTS_TRANSFER];
        _contractTransfer = new ethers.Contract(Transfer.address, Transfer.abi, _provider);

        let Slotie = Contracts[chainId][Config.NETWORK.CHAIN_ID_TO_NAME[chainId]]["contracts"][Config.CONTRACTS.SLOTIE];
        _contractSlotie = new ethers.Contract(Slotie.address, Slotie.abi, _provider);

        let Bridge = Contracts[chainId][Config.NETWORK.CHAIN_ID_TO_NAME[chainId]]["contracts"][Config.CONTRACTS.ETH_BRIDGE]
        _contractBridge = new ethers.Contract(Bridge.address, Bridge.abi, _provider);
      } else {
        let Watts = Contracts[chainId][Config.NETWORK.CHAIN_ID_TO_NAME[chainId]]["contracts"][Config.CONTRACTS.WATTS_BSC];
        _contractWatts = new ethers.Contract(Watts.address, Watts.abi, _provider);

        let Bridge = Contracts[chainId][Config.NETWORK.CHAIN_ID_TO_NAME[chainId]]["contracts"][Config.CONTRACTS.BSC_BRIDGE]
        _contractBridge = new ethers.Contract(Bridge.address, Bridge.abi, _provider);

        let Transfer =
          Contracts[chainId][Config.NETWORK.CHAIN_ID_TO_NAME[chainId]]["contracts"][Config.CONTRACTS.WATTS_TRANSFER_BSC];
        _contractTransfer = new ethers.Contract(Transfer.address, Transfer.abi, _provider);

        let Slotie = Contracts[ethChainID][Config.NETWORK.CHAIN_ID_TO_NAME[ethChainID]]["contracts"][Config.CONTRACTS.SLOTIE];
        _contractSlotie = new ethers.Contract(Slotie.address, Slotie.abi, _ethProvider);

        _contractClaim = {}
      }
    }

    setProvider(_provider);
    setSigner(_signer);
    setAddress(_address);
    setContractWatts(_contractWatts);
    setContractClaim(_contractClaim);
    setContractTransfer(_contractTransfer);
    setContractSlotie(_contractSlotie);
    setContractBridge(_contractBridge);
    setIsConnected(true);
    setChainId(chainId);
    setIsCorrectChain(_isCorrectChain);
  }

  async function tryAutoConnect() {
    try {
      let { cachedProvider } = web3Modal;
      let cachedFromStorage = JSON.parse(localStorage.getItem("WEB3_CONNECT_CACHED_PROVIDER"));

      if (cachedProvider && cachedProvider !== "") {
        await initiateWeb3(await web3Modal.connectTo(cachedProvider));
        return true;
      } else if (cachedFromStorage && cachedFromStorage != "") {
        await initiateWeb3(await web3Modal.connectTo(cachedFromStorage));
        return true;
      } else {
        return false;
      }
    } catch (e) {
      return false;
    }
  }

  async function connect() {
    setIsConnecting(true);
    try {
      // await web3Modal.clearCachedProvider();
      let _proxy = await web3Modal.connect();
      await initiateWeb3(_proxy);
    } catch (e) {
      if (e && e.message) alert(e.message);
    }
    setIsConnecting(false);
  }

  return [
    provider,
    signer,
    address,
    contractWatts,
    contractClaim,
    contractTransfer,
    contractSlotie,
    contractBridge,
    isConnected,
    chainId,
    tryAutoConnect,
    triedAutoConnecting,
    setTriedAutoConnecting,
    connect,
    isConnecting,
    isCorrectChain
  ];
}
