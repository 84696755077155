import React, { useState, useEffect, useCallback } from "react";
import { BigNumber, ethers } from "ethers";
import "./transfer.css";
import useWeb3 from "../hooks/useWeb3";
import axios from "axios";

const BridgeApiUrl = "https://24m9lgr2ea.execute-api.us-east-1.amazonaws.com/production/api/requests/get"
export default function Claim() {
  useEffect(() => {
    window.Webflow && window.Webflow.destroy();
    window.Webflow && window.Webflow.ready();
    window.Webflow && window.Webflow.require("ix2");
    //document.dispatchEvent(new Event('readystatechange'))
  });

  const [
    provider,
    signer,
    address,
    contractWatts,
    contractClaim,
    contractTransfer,
    contractSlotie,
    contractBridge,
    isConnected,
    chainId,
    tryAutoConnect,
    triedAutoConnecting,
    setTriedAutoConnecting,
    connect,
    isConnecting,
    isCorrectChain
  ] = useWeb3();

  const now = () => Math.floor(Date.now() / 1000);
  const zeroBalance = ethers.BigNumber.from(10).pow(16);

  const [wattsClaimableBalance, setWattsClaimableBalance] = useState();
  const [wattsBalance, setWattsBalance] = useState();
  const [correctAmount, setCorrectAmount] = useState();
  const [bridgeAmount, setBridgeAmount] = useState(0);
  
  /// Reguests
  const [claimAmount, setClaimAmount] = useState();
  const [requests, setRequests] = useState([]);

  const isDataLoaded = () =>
    wattsBalance !== undefined && wattsClaimableBalance !== undefined && claimAmount !== undefined;
  const isWattsBalanceZero = () => isDataLoaded() && wattsBalance.eq(0) && wattsClaimableBalance.eq(0);

  const twoDecimals = str => {
    //console.log(str)
    const split = str.split(".");
    //console.log(split)
    if (split.length > 1) {
      return split[0] + "." + split[1].substring(0, 2);
    } else {
      return str;
    }
  };

  const isEth = (chainId) => {
    return chainId === "1" || chainId === "5";
  }

  const bnToString = (bn) => {
    return twoDecimals(ethers.utils.formatEther(bn.toString()))
  }

  const getBalances = async () => {
    if (address && contractWatts && contractTransfer) {
      console.log("Getting balances");
      if (isEth(chainId)) {
        try {
          let totalWattsBalance = await contractWatts.balanceOf(address);
          let claimableWattsBalance = await contractTransfer.WATTSOWNER_seeClaimableBalanceOfUser(address);

          setWattsClaimableBalance(claimableWattsBalance);
          setWattsBalance(totalWattsBalance.sub(claimableWattsBalance));
        } catch (e) {
          console.log(e);
        }
      } else {
        try {
          let totalWattsBalance = await contractWatts.balanceOf(address);
          let claimableWattsBalance = ethers.BigNumber.from(0);

          setWattsClaimableBalance(claimableWattsBalance);
          setWattsBalance(totalWattsBalance.sub(claimableWattsBalance));
        } catch (e) {
          console.log(e);
        }
      }
    }
  };

  const getBridgeRequests = async () => {
    if (address && chainId) {
      const res = await axios.get(BridgeApiUrl, {
        params: {
          wallet_address: address,
        },
      });
      if (res.data && res.data.success) {
        const requests = res.data.data.requests.filter(r => r.chain_id !== parseInt(chainId));
        const totalClaimAmount = requests.reduce((acc, cur) => {
          return acc.add(cur.amount);
        }, ethers.BigNumber.from(0));
        setClaimAmount(totalClaimAmount);
        setRequests(requests);
      } else {
        //setClaimAmount(ethers.BigNumber.from(0));
      }
    }
  }

  useEffect(() => {
    getBalances();
    getBridgeRequests();
  }, [address, contractWatts, contractTransfer, chainId]);

  useEffect(() => {
    if (provider && contractWatts && contractTransfer) {
      provider.on("block", _ => {
        console.log("block update");
        getBalances();
      });
      return () => provider.off("block");
    }
  }, [provider, contractWatts, contractTransfer]);
  //useOnBlock(injectedProvider, () => console.log("avaa"));

  const [isBridging, setIsBridging] = useState(false);
  const [isError, setIsError] = useState(false);
  const [inputError, setInputError] = useState();
  const [isBridged, setIsBridged] = useState(false);
  const [isClaimed, setIsClaimed] = useState(false);

  const [status, setStatus] = useState(0);
  const [txId, setTxId] = useState("");

  useEffect(() => {
    checkTx();
  }, [status]);

  const checkTx = () => {
    if (provider) {
      const txId = localStorage.getItem("PENDING_TX");
      console.log("txId", txId);
      if (txId && txId !== "") {
        provider.getTransactionReceipt(txId).then(receipt => {
          console.log("getTransactionReceipt", receipt);
          if (receipt) {
            localStorage.setItem("PENDING_TX", "");
            setIsBridging(false);
            setStatus(0);
            setTxId(receipt.transactionHash);
            if (receipt.status == true) {
              if (requests.length > 0) {
                setClaimAmount(_ => _.sub(requests[requests.length - 1].amount));
                setRequests(_ => _.slice(0, -1))
                if (requests.length === 1) {
                  setIsClaimed(true);
                }
              } else {
                setIsBridged(true);
              }
            } else {
              setIsError(true);
            }
            getBalances();
          } else {
            setTimeout(checkTx, 1000);
          }
        });
      }
    }
  };

  const areInputsCorrect = () => inputError === undefined && correctAmount;

  const setAmountFromInput = value => {
    let amount = parseFloat(value);

    if (isNaN(amount)) {
      setInputError("Please enter a valid number for the amount");
      setCorrectAmount(false);
      return;
    }

    if (amount <= 0) {
      setInputError("Please enter a number above 0");
      setCorrectAmount(false);
      return;
    }

    amount = Math.floor(amount * 10 ** 5);

    amount = ethers.BigNumber.from(10)
      .pow(18 - 5)
      .mul(amount);

    if (wattsBalance.add(wattsClaimableBalance).lt(amount)) {
      setInputError(
        `Amount too high. Maximum you can bridge is ${twoDecimals(
          ethers.utils.formatEther(wattsBalance.add(wattsClaimableBalance).toString()),
        )} watts`,
      );
      setCorrectAmount(false);
      return;
    }

    setBridgeAmount(amount);
    setInputError(undefined);
    setCorrectAmount(true);
  };

  const bridge = async () => {
    setIsError(false)
    setIsBridged(false)
    setIsBridging(true);

    if (inputError !== undefined) {
      alert("There are still some input errors.");
      return;
    }

    if (bridgeAmount == undefined) {
      alert("Please enter a valid amount to send");
      return;
    }

    contractBridge
      .connect(signer)
      .bridgeWatts(bridgeAmount)
      .then(response => {
        console.log("response", response);
        localStorage.setItem("PENDING_TX", response.hash);
        setStatus(1);
        return response.hash;
      })
      .catch(error => {
        console.log(error);
        setStatus(0);
        setIsBridging(false);
        setIsError(true);
      });
  };

  const claim = async () => {
    setIsError(false)
    setIsBridged(false)
    setIsBridging(true);

    const latestRequest = requests[requests.length - 1];

    contractBridge
      .connect(signer)
      .receiveWatts(latestRequest.amount, latestRequest.random_bytes, latestRequest.signature)
      .then(response => {
        console.log("response", response);
        localStorage.setItem("PENDING_TX", response.hash);
        setStatus(1);
        return response.hash;
      })
      .catch(error => {
        console.log(error);
        setStatus(0);
        setIsBridging(false);
        setIsError(true);
      });
  };

  return (
    <div className="page-wrapper">
      <div className="nav">
        <div
          data-animation="default"
          data-collapse="medium"
          data-duration="400"
          data-easing="ease"
          data-easing2="ease"
          role="banner"
          className="navbar w-nav"
        >
          <div className="wrapper navbar-wrapper">
            <div className="container navbar-container">
              <a href="https://watts.slotie.com" className="brand w-nav-brand">
                <img src="images/Slotie-Logo.svg" loading="eager" alt="Slotie Logo." className="logo nav-logo" />
                <div className="text logo-text">Slotie</div>
              </a>
              <nav role="navigation" className="nav-menu w-nav-menu">
                <div className="grid nav-menu-grid">
                  <div className="grid nav-links-grid">
                    <a href="index.html" className="nav-link w-nav-link">
                      Home
                    </a>
                  </div>
                  <div className="grid social-links-grid">
                    <a
                      id="w-node-_8f78bbae-74c8-aa00-d66c-8c5459c9010e-9ab278cb"
                      href="https://twitter.com/SlotieNft"
                      target="_blank"
                      className="link-block social-link w-inline-block"
                      rel="noreferrer"
                    >
                      <img
                        src="images/Logo-white-2.svg"
                        loading="eager"
                        alt="Twitter Logo."
                        className="icon social-icon"
                      />
                    </a>
                    <a
                      href="https://discord.gg/slotie"
                      target="_blank"
                      className="link-block social-link w-inline-block"
                      rel="noreferrer"
                    >
                      <img
                        src="images/Discord-Logo-White-1.svg"
                        loading="eager"
                        alt="Discord Logo."
                        className="icon social-icon"
                      />
                    </a>
                  </div>
                </div>
                <div className="horizontal-line navbar-line mobile-navbar-line"></div>
              </nav>
              <div className="menu-button w-nav-button">
                <div className="w-icon-nav-menu"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="horizontal-line navbar-line"></div>
      </div>

      {
        isCorrectChain === false ? (
          <section id="Claim" className="section wallet-section">
            <div className="container"> 
            <div className="wrapper card">
              <h3 className="heading heading-xl no-margin" style={{ textAlign: "center" }}>
                Your wallet is connected to an unsupported network. Please switch your network to Ethereum or Binance Smart Chain.
              </h3>
            </div>
            </div>
          </section>
        ) : (
          <section id="Claim" className="section wallet-section">
            <div className="container">
              <div className="wrapper heading-block-wrapper">
                <div className="horizontal-line grow"></div>
                <div className="spacer _1em"></div>
                <h1 className="heading heading-xl no-margin">Bridge Watts</h1>
                <div className="spacer _1em"></div>
                <div className="horizontal-line grow"></div>
              </div>
              <div className="spacer _2em"></div>
              <div className="wrapper card">
                <div className="grid wallet-grid">
                  <div className="wrapper">
                    <h2>You can bridge watts here.</h2>
                    <p className="text">
                      Enter the amount of watts you want to bridge and press bridge. 
                      The bridge should take a few minutes to complete. When your bridge transactions is confirmed
                      you need to switch your wallet to the {isEth(chainId) ? "BSC" : "ETH"} and press the claim button to claim your watts.
                    </p>

                    {!isConnected && !isConnecting && (
                      <a
                        onClick={e => {
                          e.preventDefault();
                          connect();
                        }}
                        href="#"
                        className="button w-button"
                      >
                        Connect wallet
                      </a>
                    )}

                    {isConnecting && !isConnected && (
                      <div
                        data-w-id="4a714206-6628-824b-d7eb-e3532f4fa8a8"
                        data-animation-type="lottie"
                        data-src="https://uploads-ssl.webflow.com/61a2301c1aba757f9b2521aa/61a2301c1aba75799b2521d9_lottieflow-loading-04-1-ffffff-easey.json"
                        data-loop="1"
                        data-direction="1"
                        data-autoplay="1"
                        data-is-ix2-target="0"
                        data-renderer="svg"
                        data-default-duration="3.1458333333333335"
                        data-duration="0"
                        className="lottie loading-lottie"
                      ></div>
                    )}

                    {isConnected && (
                      <div className="tag connected-tag">
                        Wallet Connected {chainId ? isEth(chainId) ? "on ETH" : "on BSC" : ""}<span className="span"></span>
                      </div>
                    )}
                  </div>

                  <div className="wallet-line"></div>

                  <div id="w-node-_6cbb3016-9612-9d1b-574c-2c7813d436db-9ab278cb" className="wrapper wallet-column right">
                    {!isConnected && (
                      <>
                        <div className="wrapper wallet-block wallet-phase-01">
                          <div className="wrapper wallet-row">
                            <div className="heading wallet-heading">Please connect your wallet to bridge your watts.</div>
                          </div>
                        </div>
                      </>
                    )}
                    {isConnected && !isDataLoaded() && (
                      <div className="wrapper wallet-block wallet-phase-01">
                        <div
                          data-w-id="4a714206-6628-824b-d7eb-e3532f4fa8a8"
                          data-animation-type="lottie"
                          data-src="https://uploads-ssl.webflow.com/61a2301c1aba757f9b2521aa/61a2301c1aba75799b2521d9_lottieflow-loading-04-1-ffffff-easey.json"
                          data-loop="1"
                          data-direction="1"
                          data-autoplay="1"
                          data-is-ix2-target="0"
                          data-renderer="svg"
                          data-default-duration="3.1458333333333335"
                          data-duration="0"
                          className="lottie loading-lottie"
                        ></div>
                        <div className="text text-small">Loading your data.</div>
                      </div>
                    )}
                    {isConnected && isDataLoaded() && (wattsBalance.add(wattsClaimableBalance).gte(zeroBalance) || isBridged) && claimAmount.eq(0) && !isClaimed && (
                      <>
                        {
                          !isBridged && (
                            <div className="wrapper wallet-block wallet-phase-03">
                              <div className="wrapper wallet-row">
                                <div className="heading wallet-heading">Bridge Network:</div>
                                <div className="tag wallet-tag">
                                  {isEth(chainId) ? "ETH to BSC" : "BSC to ETH"}
                                  <span className="span"></span>
                                </div>
                              </div>
                              <div className="wrapper wallet-row">
                                <div className="heading wallet-heading">My Total Balance:</div>
                                <div className="tag wallet-tag">
                                  {twoDecimals(ethers.utils.formatEther(wattsBalance.add(wattsClaimableBalance).toString()))}
                                  <span className="span"></span>
                                </div>
                              </div>
                              <div className="wrapper input-item" style={{ marginTop: "10px"}}>
                                <div className="wallet-heading" style={{ marginBottom: "5px" }}>Please enter the amount of $WATTs to bridge</div>
                                <input type="number" onChange={value => setAmountFromInput(value.target.value)} />
                              </div>
                            </div>
                          )
                        }

                        {areInputsCorrect() && !isBridged && (
                          <div className="wrapper claim-watts-wrapper">
                            {!isBridging && !isError && (
                              <a
                                onClick={e => {
                                  e.preventDefault();
                                  bridge();
                                }}
                                href="#"
                                className="button w-button"
                              >
                                Bridge
                              </a>
                            )}

                            {isBridging && (
                              <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                <div
                                  data-w-id="7c63db2b-ec3c-cf00-594d-cf6c121b75f3"
                                  data-animation-type="lottie"
                                  data-src="https://uploads-ssl.webflow.com/61a2301c1aba757f9b2521aa/61a2301c1aba75799b2521d9_lottieflow-loading-04-1-ffffff-easey.json"
                                  data-loop="1"
                                  data-direction="1"
                                  data-autoplay="1"
                                  data-is-ix2-target="0"
                                  data-renderer="svg"
                                  data-default-duration="3.1458333333333335"
                                  data-duration="0"
                                  className="lottie loading-lottie"
                                ></div>

                                {status === 0 && (
                                  <div className="text text-small" style={{ marginLeft: 10 }}>
                                    Please confirm the transaction.
                                  </div>
                                )}

                                {status === 1 && (
                                  <div className="text text-small" style={{ marginLeft: 10 }}>
                                    Transaction is loading.
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        )}
                        {!isBridging && isBridged && (
                          <>
                            <div className="watts-claimed">
                                <span className="span">
                                  You have successfully bridged {bnToString(bridgeAmount)} WATTs from { isEth(chainId) ? "Ethereum to Binance Smart Chain" : "Binance Smart Chain to Ethereum" }.
                                  Please switch you wallet to the { isEth(chainId) ? "Binance" : "Ethereum" } network and refresh this page to claim your bridged WATTs.
                                </span>
                            </div>
                            {/*<div className="watts-claimed">
                              <div className="tag connected-tag">
                                <span className="span">TRANSACTION SUCCEEDED!</span>
                              </div>
                        </div>*/}
                          </>
                        )}
                        {!isBridging && isError && <div className="tag error-tag">Transaction failed</div>}
                        {!isBridging && inputError && <div className="tag error-tag">{inputError}</div>}
                      </>
                    )}
                    {isConnected && isDataLoaded() && (claimAmount.gt(0) || isClaimed) && (
                      <>
                        {
                          !isClaimed && (
                            <>
                              <div className="wrapper wallet-block wallet-phase-03">
                                <div className="wrapper wallet-row">
                                  <p className="text">
                                    You have outstanding claims from previously bridged Watts on {isEth(chainId) ? "Binance Smart Chain" : "Ethereum"}.
                                    You can claim them by clicking the button below.
                                    You have to claim {requests.length} time{requests.length > 1 ? "s" : ""} to receive all your WATTs. This is because you 
                                    previously submitted {requests.length} claim{requests.length > 1 ? "s" : ""} to the bridge contract.
                                  </p>
                                </div>
                                <div className="wrapper wallet-row">
                                  <div className="heading wallet-heading">Connected Chain:</div>
                                  <div className="tag wallet-tag">
                                    {isEth(chainId) ? "ETH" : "BSC"}
                                    <span className="span"></span>
                                  </div>
                                </div>
                                <div className="wrapper wallet-row">
                                  <div className="heading wallet-heading">Total Claimable WATTs:</div>
                                  <div className="tag wallet-tag">
                                    {twoDecimals(ethers.utils.formatEther(claimAmount.toString()))}
                                    <span className="span"></span>
                                  </div>
                                </div>
                                <div className="wrapper wallet-row">
                                  <div className="heading wallet-heading">Claims Left:</div>
                                  <div className="tag wallet-tag">
                                      {requests.length}
                                    <span className="span"></span>
                                  </div>
                                </div>
                              </div>

                              <div className="wrapper claim-watts-wrapper">
                                {!isBridging && !isError && (
                                  <a
                                    onClick={e => {
                                      e.preventDefault();
                                      claim();
                                    }}
                                    href="#"
                                    className="button w-button"
                                  >
                                    Claim
                                  </a>
                                )}

                                {isBridging && (
                                  <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                    <div
                                      data-w-id="7c63db2b-ec3c-cf00-594d-cf6c121b75f3"
                                      data-animation-type="lottie"
                                      data-src="https://uploads-ssl.webflow.com/61a2301c1aba757f9b2521aa/61a2301c1aba75799b2521d9_lottieflow-loading-04-1-ffffff-easey.json"
                                      data-loop="1"
                                      data-direction="1"
                                      data-autoplay="1"
                                      data-is-ix2-target="0"
                                      data-renderer="svg"
                                      data-default-duration="3.1458333333333335"
                                      data-duration="0"
                                      className="lottie loading-lottie"
                                    ></div>

                                    {status === 0 && (
                                      <div className="text text-small" style={{ marginLeft: 10 }}>
                                        Please confirm the transaction.
                                      </div>
                                    )}

                                    {status === 1 && (
                                      <div className="text text-small" style={{ marginLeft: 10 }}>
                                        Transaction is loading.
                                      </div>
                                    )}
                                  </div>
                                )}
                              </div>
                            </>
                          )
                        }

                        {!isBridging && isClaimed && (
                          <>
                            <div className="watts-claimed">
                                <span className="span">
                                  Congratulations, you have claimed all your WATTs and thus completed the bridge from 
                                  {isEth(chainId) ? " Binance Smart Chain to Ethereum" : " Ethereum to Binance Smart Chain"}.
                                  
                                  You can always bridge back your WATTS to {isEth(chainId) ? "Binance Smart Chain" : "Ethereum"} by refreshing this page.
                                </span>
                            </div>
                          </>
                        )}
                        {!isBridging && isError && <div className="tag error-tag">Transaction failed</div>}
                        {!isBridging && inputError && <div className="tag error-tag">{inputError}</div>}
                      </>
                    )}
                    {isConnected && isDataLoaded() && wattsBalance.add(wattsClaimableBalance).lt(zeroBalance) && claimAmount.eq(0) && !isBridged && !isClaimed && (
                      <div className="wrapper wallet-block wallet-phase-03">
                        <div className="wrapper wallet-row">
                            <div className="heading wallet-heading">Your WATTs balance is zero. Please obtain WATTS to make use of this service.</div>
                        </div>
                    </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
        )
      }
      
      <footer className="section footer-section">
        <div className="container footer-container">
          <div className="grid footer-grid">
            <a
              href="https://www.slotie.com"
              id="w-node-fa8bc22b-940a-a591-4ef0-fb26e11756cb-9ab278cb"
              className="brand w-nav-brand"
            >
              <img src="images/Slotie-Logo.svg" loading="eager" alt="Slotie Logo." className="logo footer-logo" />
              <div className="text logo-text footer-logo-text">Slotie</div>
            </a>
            <div className="grid footer-subgrid">
              <div className="text">
                Become a member of the strongest and fast growing DeFi gambling community.
                <br />
              </div>
              <div className="grid social-links-grid">
                <a
                  id="w-node-_85111364-03ec-d5ca-8481-6c611792f731-9ab278cb"
                  href="https://twitter.com/SlotieNft"
                  target="_blank"
                  className="link-block social-link w-inline-block"
                  rel="noreferrer"
                >
                  <img src="images/Logo-white-2.svg" loading="eager" alt="Twitter Logo." className="icon social-icon" />
                </a>
                <a
                  href="https://discord.gg/slotie"
                  target="_blank"
                  className="link-block social-link w-inline-block"
                  rel="noreferrer"
                >
                  <img
                    src="images/Discord-Logo-White-1.svg"
                    loading="eager"
                    alt="Discord Logo."
                    className="icon social-icon"
                  />
                </a>
              </div>
            </div>
            <div className="horizontal-line grow"></div>
            <div className="grid footer-subgrid">
              <div className="text text-small">
                © 2022 Slotie NFT. All rights reserved.
                <br />
              </div>
              <a href="https://www.slotie.com/terms" target="_blank" className="link terms-link" rel="noreferrer">
                Terms &amp; Conditions
              </a>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}
